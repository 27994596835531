import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { Link } from "gatsby";
import Columns from "../components/columns";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import TryButton from "../components/TryButton";
import { FaList, FaCalendarDay, FaChartLine, FaUsers, FaBullhorn, FaInfo, FaCogs } from "react-icons/fa";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Container from "../components/container";
import Lightbox from "../components/lightBox";
import styles from "./index.module.css";
import Tile from "../components/Tile";



export const query = graphql`
  query {
   zdjecie6: file(relativePath: { eq: "szansa-sprzedazy-nowy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie5: file(relativePath: { eq: "wspoldzielenie-kalendarza.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
           }
      }
    }
     zdjecie4: file(relativePath: { eq: "karta-kontrahenta-zrzut.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
     zdjecie3: file(relativePath: { eq: "pulpit-nowy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
               }
      }
    }
     zdjecie2: file(relativePath: { eq: "raporty-crm-nowe.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp

           
    
        }
      }
    }
  }
`;

const SystemCRM = ({ data }) => {
  return (
    <FunctionPageLayout title="CRM dla średniej firmy" headline="Szukasz systemu CRM dla przedsiębiorstwa do 50 pracowników?">
      <SEO
        title="Prosty CRM dla średniej firmy"
        description=" System CRM dla średniej firmy ✅ Szanse sprzedaży • Koordynacja pracy • Baza wiedzy o klientach • Wypróbuj za darmo!"
      />
    <center> <h2> Potrzebujesz systemu CRM dla średniej firmy, który uporządkuje Twoje procesy sprzedażowe? </h2> </center>
    <br />
    <br />
    <Columns>
    <div>

     <p>Stworzyliśmy <strong>prosty system CRM dla średniej firmy</strong>. Quick CRM jest przeznaczony do zarządzania procesami sprzedażowymi oraz
     koordynowania działań handlowców. </p>


<p>Na tym etapie rozwoju firmy często <strong>szukzają sposobu na uporządkowanie i&nbsp;optymalizację procesów</strong>. 

System CRM dla średniej firmy wspiera przedsiębiorstwa w obszarze tworzenia kompletnej bazy kontrahentów, 
koordynacji pracy czy już wcześniej wspomnianych procesach sprzedażowych.
<strong> Sprawdzone narzędzie w postaci widoku szans sprzedaży to źródło wiedzy na temat kampanii sprzedażowych, 
które są nieocenioną pomocą w pracy handlowca</strong>. </p>
<p>Zależy Ci na rozwoju firmy? Sprawdź, <strong> czy Twój dostawca oferuje oprogramowanie</strong>, 
które sprosta wyzwaniom zmiennego otoczenia i nowym potrzebom Twojego przedsiębiorstwa. </p>   
</div>
<div>
<Lightbox images={[data.zdjecie3]} 
      alts={["Prosty system CRM dla średniej firmy. Kalendarz i planowanie pracy."]}/>
</div>
</Columns>
<br />
<br />
<center><h2> Najważniejsze funkcje prostego systemu CRM </h2></center>

 <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap"
          }}
        >
          <Tile to="/sprzedaz-w-crm/">
            <FaList size="70px" />
            <h3>Szanse 
            <br />sprzedaży</h3>
          </Tile>
          <Tile to="/kalendarz-i-zadania/">
            <FaCalendarDay size="70px" />
            <h3>Koordynacja 
            <br />pracy</h3>
          </Tile>
          <Tile to="/raporty/">
            <FaChartLine size="70px" />
            <h3>Raportowanie</h3>
          </Tile>
          <Tile to="/komunikator/">
            <FaBullhorn size="70px" />
            <h3>
              Sprawna komunikacja
            </h3>
          </Tile>
        </div>
      </Container>
        <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap"
          }}
        >
          <Tile to="/kartoteki-kontrahentow/">
            <FaUsers size="70px" />
            <h3>Kartoteki
            <br />kontrahentów</h3>
          </Tile>
          <Tile to="/pulpit-pracownika/">
            <FaInfo size="70px" />
            <h3>Dynamiczne 
            <br />pulpity</h3>
          </Tile>
          <Tile to="/zarzadzanie-uprawnieniami/">
            <FaCogs size="70px" />
            <h3>Zarządzanie< br/> uprawnieniami</h3>
          </Tile>
        </div>
      </Container>
<br />
<br />
<center><h2> Obszary wspierane przez system CRM dla średniej firmy </h2> </center>
<br />
<br />
<Columns>
<div>
<h3> Zarządzanie szansami sprzedaży </h3>
<p>Jedna z najważniejszych funkcji systemu CRM dla średniej firmy. Zarządzanie szansami sprzedaży
 <strong> uporządkuje oraz zoptymalizuje proces sprzedaży i&nbsp;obsługi klienta B2B. Handlowcom ułatwi zarządznaie szansami sprzedaży i realizację celów, kierownikowi 
 - weryfikację i planowanie działań handlowców</strong>, a przede wszystkim zaoszczędzi czas. 
</p>
</div>
<div>
<Lightbox images={[data.zdjecie6]} 
      alts={["Prosty CRM dla średniej firmy. Zarządzanie szansami sprzedaży"]}/>


</div>
</Columns>

<br />
<br />
<Columns>
<div>

<Lightbox images={[data.zdjecie5]} 
      alts={["Prosty CRM dla średniej firmy. Koordynacja pracy w systemie CRM."]}/>
     
     
</div>
<div>
<br />
<br />
<h3> Koordynacja pracy </h3>
<p> Dzięki funkcji planowania i koordynacji pracy, <strong>delegowanie zadań 
i ich monitorowanie staje się o wiele łatwiejsze</strong>. Pracownicy mogą 
współdzielić kalendarze, 
sprawdzić przydzielone im zadania, wiązać zadania z obiektami (np. szansami sprzedaży). Natomiast kierownik na bieżąco
może weryfikować działania handlowców, bez konieczności poświęcania czasu na zbieranie informacji.
</p>
</div>
</Columns>
<br />
<br />
<Columns>
<div>
<h3> Baza wiedzy o kontrahentach </h3>
<p>Baza wiedzy o kontrahentach jest <strong>uporządkowanym, kompletnym źródłem wiedzy o współpracy z klientami</strong>. 
Zbiera podstawowe informacje o firmie, kontaktach,
a także o <strong>historii współpracy, zaplanowaych zadaniach, 
ustaleniach z klientem czy powiązanych umowach</strong>.  </p>
</div>
<div>
<Lightbox images={[data.zdjecie4]} 
      alts={["Prosty CRM dla średniej firmy. Baza wiedzy o klientach."]}/>
      

</div>
</Columns>
<br />
<br />
<Columns>
<div>
<Lightbox images={[data.zdjecie2]} 
      alts={["Prosty CRM dla średniej firmy. Sprawne raportowanie w CRM."]}/>
</div>
<div>


<h3> Analiza sprzedaży </h3>
<p>Raporty dostępne w systemie <strong>dostarczają wiele cennych informacji</strong>. Podczas ich analizowania dowiesz się, 
gdzie występują wąskie gardła w&nbsp;procesie,
dlaczego szanse sprzedaży są tracone, który z handlowców 
jest najskuteczniejszy, a który potrzebuje wsparcia.
Jest to <strong>nieoceniona pomoc <br />w efektywnym zarządzaniu sprzedażą</strong>. </p>
</div>
</Columns>







   

    </FunctionPageLayout>
  );
};

export default SystemCRM;
